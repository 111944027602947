<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter une permission') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <v-row class="pt-5">
          <v-col
            cols="12"
          >
            <v-select
              v-model="item.tab"
              outlined
              dense
              :label="$t('Ongle')"
              :placeholder="$t('Ongle')"
              hide-details="auto"
              :items="tabs"
              item-text="label"
              item-value="tab"
            ></v-select>
          </v-col>
          <v-col
            cols="6"
          >
            <v-select
              v-model="item.group"
              outlined
              dense
              :rules="[validators.required]"
              :label="$t('Group')"
              :placeholder="$t('Group')"
              hide-details="auto"
              :items="tabs.find(tab => tab.tab === item.tab) != null ?tabs.find(tab => tab.tab === item.tab).columns.filter(ele => ele.value):[]"
              item-text="text"
              item-value="value"
            ></v-select>
          </v-col>
          <v-col
            cols="6"
          >
            <v-text-field
              v-model="item.group"
              outlined
              dense
              :rules="[validators.required]"
              :label="$t('Group')"
              :placeholder="$t('Group')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-select
              v-model="item.type"
              outlined
              dense
              :rules="[validators.required]"
              :error-messages="errorMessages.type"
              :label="$t('Action')"
              :placeholder="$t('Action')"
              hide-details="auto"
              :items="types"
              item-text="text"
              item-value="value"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="item.name"
              outlined
              dense
              :rules="[validators.required]"
              :error-messages="errorMessages.name"
              :label="$t('Code')"
              :placeholder="$t('Code')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
          >
            <v-text-field
              v-model="item.display_name"
              outlined
              dense
              :label="$t('Nom')"
              :placeholder="$t('Nom')"
              hide-details="auto"
            ></v-text-field>
          </v-col>
<!--          <v-col
            cols="12"
            sm="6"
          >
            <v-switch
              v-model="item.is_task"
              :label="$t('Est une tache')"
              :true-value="1"
              :false-value="0"
            ></v-switch>
          </v-col>-->
        </v-row>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
        <slot name="form-cancel"></slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Enregistrer") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mdiClose, mdiPencilOutline, mdiPencilOffOutline } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import store from '@/store'
import controller from './Controller'

export default {
  components: { },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
      tabs,
      types,
      items,
    } = controller()
    const errorMessages = ref({ })
    const valid = ref(false)
    const loading = ref(false)
    const isLoadingPermissions = ref(false)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        store.dispatch('permission/addItem', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return false
            }
            item.value = response.data
            var index = items.value.findIndex(ele => ele.id === response.data.id )
            if(index < 0){
              items.value.push(response.data)
            }
            //resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }
    // eslint-disable-next-line no-unused-vars
    /* watch(() => props.object, (newValue, prevValue) => {
      if (!newValue || !newValue.id) {
        resetItem()
      } else {
        item.value = JSON.parse(JSON.stringify(newValue))
      }
    }) */
    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */

    return {
      tabs,
      types,
      items,
      resetItem,
      form,
      isLoadingPermissions,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      item,
      valid,
      loading,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
