<template>
  <div id="user-list">
    <!-- app drawer -->
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <permission-form
        v-model="item"
        :is-form-active="isFormActive"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter une permission') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template #form-cancel>
          <v-btn
            :loading="loading"
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </permission-form>
    </v-dialog>
    <v-dialog
      v-model="generateDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 500 : '100%'"
    >
      <permission-generator
        v-model="item"
        :is-form-active="generateDialog"
        @refetch-data="fetchItems"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Generer') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="generateDialog = !generateDialog"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template #form-cancel>
          <v-btn
            :loading="loading"
            color="secondary"
            outlined
            type="reset"
            @click="generateDialog = !generateDialog"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </permission-generator>
    </v-dialog>
    <!-- permission total card -->

    <!-- list filters -->
    <v-card>
      <v-card-title>
        {{ $t("Permissions") }}<v-chip outlined class="ml-2">{{ totalItems }}</v-chip>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click.stop="openDialogGenerate"
        >
          <span>{{ $t("Generer") }}</span>
        </v-btn>
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click.stop="openDialog(false)"
        >
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          <span>{{ $t("Ajouter une Permission") }}</span>
        </v-btn>
      </v-card-title>
      <v-divider class="mt-4"></v-divider>

      <v-card-text>
        <v-expansion-panels>
          <v-expansion-panel v-for="tab in tabs" :key="tab.tab">
            <v-expansion-panel-header>{{ tab.label}} ({{ items.filter(ele => ele.tab === tab.tab && ['view','see'].includes(ele.type) ).length }})</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list dense>
                <template v-for="perm in items.filter(ele => ele.tab === tab.tab && ['view','see'].includes(ele.type) )">
                  <v-list-group
                    dense
                    ref="refVListGroup"
                    class="vertical-nav-menu-group"
                  >
                    <template #activator>
                      <v-list-item-title>{{ perm.display_name }}</v-list-item-title>
                    </template>

                    <v-list-item dense v-for="child in items.filter(ele => ele.tab === tab.tab && ele.group === perm.group)">
                      <v-list-item-avatar>
                        <v-icon small  @click="openDialog(child)">{{ icons.mdiPencilOutline }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ getItem(types, 'value', child.type).text }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon small color="error"
                                @click="confirmDelete(child)">{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-list-item-action>
                    </v-list-item>

                  </v-list-group>
                </template>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel >
            <v-expansion-panel-header>Non classé ({{ items.filter(ele => !ele.tab || !ele.group ).length }})</v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-list dense>

                    <v-list-item dense v-for="child in items.filter(ele =>  (!ele.tab || !ele.group))">
                      <v-list-item-avatar>
                        <v-icon small  @click="openDialog(child)">{{ icons.mdiPencilOutline }}</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ child.display_name }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon small color="error"
                                @click="confirmDelete(child)">{{ icons.mdiDeleteOutline }}</v-icon>
                      </v-list-item-action>
                    </v-list-item>

              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
<!--      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="2"
            cols="3"
          >
            <rows-per-page :options="options" />
          </v-col>

          <v-col
            lg="10"
            cols="9"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="page"
              total-visible="6"
              :length="pageCount"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>-->
    </v-card>
    <v-dialog
      v-model="deleteDialog"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 300 : '100%'"
    >
      <v-card>
        <v-card-title>{{ $t("Confirmer la suppression") }}</v-card-title>
        <v-divider />
        <v-card-text>{{ $t("Confirmez-vous de vouloir supprimer") }}</v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            small
            outlined
            @click="deleteDialog = false"
          >
            {{ $t("Non") }}
          </v-btn>
          <v-spacer />
          <v-btn
            :loading="loading"
            small
            color="primary"
            @click="deleteItem"
          >
            {{ $t("Oui") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="$vuetify.theme.currentTheme.error"
      :light="$vuetify.theme.dark"
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiClose,
  mdiFileDocumentOutline,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import PermissionForm from './PermissionForm.vue'
import userStoreModule from './itemStoreModule'

import controller from './Controller'
import RowsPerPage from '@/components/RowsPerPage'
import HeaderFilters from '@/components/HeaderFilters'
import PermissionGenerator from "@/views/apps/permission/PermissionGenerator";

export default {
  components: {
    PermissionGenerator,
    HeaderFilters,
    RowsPerPage,
    PermissionForm,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'permission'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })


    const {
      item,
      items,
      types,
      tabs,
      filters,
      searchQuery,
      totalItems,
      loading,
      options,
      selectedRows,
      isFormActive,
      snackBarMessage,
      isSnackbarBottomVisible,
      generateDialog,
      deleteDialog,
      confirmDelete,
      openDialog,
      openDialogGenerate,
      deleteItem,
      fetchItems,
    } = controller()
    fetchItems()
    return {
      item,
      items,
      types,
      tabs,
      filters,
      searchQuery,
      totalItems,
      loading,
      options,
      isFormActive,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      generateDialog,
      deleteDialog,
      confirmDelete,
      openDialog,
      openDialogGenerate,
      deleteItem,
      avatarText,
      fetchItems,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiClose,
        mdiExportVariant,
        mdiAccountOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
